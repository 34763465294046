<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='700'>
                    <col width='180'>
                    <col width='160'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">标题</th>
                        <th>时间</th>
                        <th>状态</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item  in data" :key="item.Id">
                        <td>
                            <div class="tdtitle dot" @click="goDetail(item)">{{item.Title}}</div>
                        </td>
                        <td class="center">
                            {{item.UpdateTime.split(' ')[0].split('/').join('-')}}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goDetail(item)" v-if="item.Hits > 0" class="read">已读</span>
                                <span @click="goDetail(item)" v-else class="unread">未读</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetUserNoticeList } from '@/api/user'
export default {
    metaInfo() {
		return {
			title: '站内信-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '站内信-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '站内信-个人中心-文物出版社'
                }
            ]
		}
	},
    components:{
        Pagination
    },
    data(){
        return{
            data:[],
            params:{
                pageIndex:1,
                pageSize:10
            },
            dialogData:{},
            total:0,
            spinning:false,
            visable:false,
        }
    },
    methods:{
        pageChange(){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetUserNoticeList(this.params)
            this.data = data
            this.total = total
            this.spinning = false
        },
        close(){
            this.visable = false
        },
        goDetail(item){
            this.$router.push({ name:'letterinfo' , params:{id:item.Id}})
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

/deep/.ant-spin-nested-loading{
    padding: 0 40px;
}
.model{
    .table{
        border-top: 0;
    }
}
.th1{
    text-align: left !important;
    padding-left: 30px;
}
td{
    height: 60px !important;
}
.center{
    text-align: center;
}
.tdtitle{
    position: relative;
    cursor: pointer;
    &:hover{
        color: #f6a75c;
    }
}
.read{
    color: rgb(136, 136, 136) !important;
    &:hover{
        color: #f6a75c !important;
    }
}

</style> 